<template>
  <div class="container-xs">
    <h2 class="app-title">
      {{ $t('bookings.my-trips') }}
    </h2>
    <div style="margin-left: 1rem; margin-right: 1rem">
      <nav style="display: grid; grid-template-columns: 1fr 1fr">
        <button
          v-for="route in routes"
          :key="route.name"
          class="booking-navigation"
          :data-active="route.isActive"
          @click="route.onClick"
        >
          <x-label tag="button">{{ route.name }}</x-label>
        </button>
      </nav>
      <div class="booking-navigation-line">
        <div
          class="booking-navigation-line-active"
          :class="{
            'booking-navigation-line-active-right':
              $route.path === '/bookings/previous',
          }"
        ></div>
      </div>
    </div>
    <div style="margin-top: 1.5rem"><router-view /></div>
  </div>
</template>

<script>
let lastBookingPage = '/bookings/upcoming'

export default {
  name: 'Bookings',
  beforeCreate() {
    if (this.$route.path === '/bookings') this.$router.replace(lastBookingPage)
  },
  computed: {
    routes() {
      return ['upcoming', 'previous'].map(type => ({
        name: this.$t(`bookings.${type}`).toUpperCase(),
        isActive: this.$route.path === `/bookings/${type}`,
        onClick: () => this.$router.push(`/bookings/${type}`),
        isCompletedActive: this.$route.path === '/bookings/previous',
      }))
    },
  },
  watch: {
    '$route.path': value => (lastBookingPage = value),
  },
}
</script>

<style scoped lang="scss">
.booking-navigation {
  color: #666;
  transition: var(--transition-fast);
  margin-top: 0.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
  &[data-active] {
    color: black;
  }
}
.booking-navigation-line {
  height: 2px;
  background: #d2d2d2;
}
.booking-navigation-line-active {
  background: var(--color-primary);
  height: 100%;
  width: 50%;
  transition: var(--transition-default);
  transition-timing-function: var(--bezier-1);
  margin-left: 0;
  &-right {
    margin-left: 50%;
  }
}
</style>
